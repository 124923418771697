import request from '@/utils/request'
// 列表
export function getTableList(data) {
  return request('post', '/apm/douyin/app/list', data)
}

// 新增修改
export function getTableAdd(data) {
  return request('post', '/apm/douyin/app/add', data)
}

// 新增修改
export function getTableEdit(data) {
  return request('post', '/apm/douyin/app/update', data)
}

// 删除
export function getTableDel(data) {
  return request('post', '/apm/douyin/app/del', data)
}