<template>
  <div class="douying">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
        <el-input v-model="tableParams.kw" placeholder="查询应用名称" @input="kwChange" clearable class="mr20" />
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增抖音应用</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="商户名称" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.merchantName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="应用名称" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.appName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="AppId" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.appId || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="AppSecret" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.apSecret || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="抖音来客账号ID" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.accountId || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
          <el-button type="text" size="medium" @click.stop="onDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
    <!-- 表单提交 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="30%">
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="140px">
        <section class="form-main">
          <el-form-item label="应用名称" prop="appName">
            <el-input v-model.trim="params.appName" autocomplete="off" maxlength="20" placeholder="请填写应用名称"></el-input>
          </el-form-item>
          <el-form-item label="抖音来客账号ID" prop="accountId">
            <el-input v-model.trim="params.accountId" autocomplete="off" maxlength="40"
              placeholder="请填写抖音来客账号ID"></el-input>
          </el-form-item>
          <el-form-item label="AppId" prop="appId">
            <el-input v-model.trim="params.appId" autocomplete="off" maxlength="40" placeholder="请填写AppId"
              :disabled="Boolean(params.id)"></el-input>
          </el-form-item>
          <el-form-item label="AppSecret" prop="apSecret">
            <el-input v-model.trim="params.apSecret" autocomplete="off" maxlength="40" placeholder="请填写AppSecret"
              :disabled="Boolean(params.id)"></el-input>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTableList,
  getTableAdd,
  getTableEdit,
  getTableDel
} from "@/api/merchant/douyin";
import { rules } from "@/db/rules";
export default {
  data() {
    return {
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        pageSize: 10,//TODO:此处的pageSize既是其他页面的size 
        kw: "",//查询关键字
        merchantId: "",//商户ID
        shopId: "",//店铺ID
      },
      // from表单
      editShow: false, //弹框开关
      editTitle: "",//弹框标题
      rules,
      params: {
        appName: "",
        accountId: "",
        appId: "",
        apSecret: "",
        merchantId: "",//商户ID
      },
    }
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
      this.params.merchantId = JSON.parse(merchantShopId)[0];
    }
  },
  mounted() {
    this.getTableList();// 【请求】表格数据
  },
  methods: {
    // 【请求】表格数据
    getTableList() {
      let data = this.tableParams;
      getTableList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】添加
    getTableAdd() {
      let data = this.params;
      getTableAdd(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.reload();// 【请求】表格数据
        }
      });
    },

    // 【请求】编辑
    getTableEdit() {
      let data = this.params;
      getTableEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.getTableList();// 【请求】表格数据
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getTableList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getTableList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },


    // 【监听】编辑
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增抖音应用";
      this.params = {
        appName: "",
        accountId: "",
        appId: "",
        apSecret: "",
        merchantId: this.params.merchantId,
      };
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑
    onEdit(row) {
      console.log('row: ', row);
      this.editShow = true;
      this.editTitle = "编辑抖音应用";
      this.params = JSON.parse(JSON.stringify(row));
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】删除
    onDel(row) {
      let data = {
        id: row.id,
      }
      this.$confirm('你确定要删除该应用吗?', '删除应用', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getTableDel(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getTableList();
          }
        })
      });
    },


    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.params.id) {
            this.getTableEdit();
          } else {
            this.getTableAdd();
          }
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.douying {
  padding: .15rem;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>